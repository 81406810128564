import { Session } from "@domains/session";

export type AuthState = {
    isLogged: boolean;
    token?: string;
    session?: Session;
};

export const INITIAL_STATE: AuthState = {
    isLogged: false,
};
