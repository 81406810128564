import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    desktop: {
        position: "fixed",
        transform: "translate(108px,0px)",
        bottom: 100,
        right: 0,
        width: 150,
        overflow: "hidden",
        backgroundColor: "#25d366",
        color: "#FFF",
        borderRadius: "4px 0 0 4px",
        zIndex: "10",
        transition: "all 0.5s ease-in-out",
        verticalAlign: "middle",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    hoveredDesktop: {
        color: "#FFFFFF",
        transform: "translate(0px,0px)",
    },
    desktopIcon: {
        fontSize: 30,
        color: "white",
        lineHeight: 30,
        padding: 10,
        transform: "rotate(0deg)",
        transition: "all 0.5s ease-in-out",
        textAlign: "center",
        display: "flex !important",
    },
    text: {
        color: "white",
        fontSize: 15,
        paddingTop: 8,
        paddingBottom: 10,
        // position: "absolute",
        // lineHeight: 16,
        fontWeight: "bolder",
    },
}));
