import LazyPage from "@components/LazyPage";
import { LayoutContextProvider } from "@providers/LayoutProvider";
import { getDetails } from "@redux/actions/user.actions";
import { ConnectedProps, connector, store } from "@redux/store";
import { isDev } from "@utils";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

type Props = ConnectedProps<{}>;

const Router: React.FC<Props> = (props) => {
    const { auth } = store.getState();

    const IndexPage = LazyPage(["index"]);
    const HomePage = LazyPage(["home"]);
    const OrdersPage = LazyPage(["orders"]);
    const SetupPage = LazyPage(["new"]);
    const PasswordForgetPage = LazyPage(["forget"]);
    const PasswordResetPage = LazyPage(["reset"]);
    const UserPage = LazyPage(["user"]);
    const UserPlanPage = LazyPage(["plan"]);

    if (!auth.isLogged) {
        return (
            <Switch>
                <Route exact path="/" component={IndexPage} />
                <Route exact path="/esqueci-a-senha" component={PasswordForgetPage} />
                <Route exact path="/redefinir-a-senha" component={PasswordResetPage} />
                <Route exact path="/cadastro" component={SetupPage} />
                <Redirect to="/" />
            </Switch>
        );
    }

    React.useEffect(() => {
        window.addEventListener("load", () => props.dispatch(getDetails()));
        return () => {
            window.removeEventListener("load", () => props.dispatch(getDetails()));
        };
    }, []);

    return (
        <Switch>
            <LayoutContextProvider>
                <Switch>
                    <Route exact path="/planos" component={HomePage} />
                    <Route exact path="/pedidos" component={OrdersPage} />
                    <Route exact path="/minha-conta" component={UserPage} />
                    <Route exact path="/meu-plano" component={UserPlanPage} />
                    <Redirect to="/minha-conta" />
                </Switch>
            </LayoutContextProvider>
            <Redirect to="/minha-conta" />
        </Switch>
    );
};

if (isDev()) Router.displayName = "Router";
export default connector(Router);
