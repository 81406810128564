import { persistor, store } from "@redux/store";
import ReactDOM from "react-dom";
import { App } from "./App";
import { setupAxios } from "./core/utils/axios";
import "./index.css";
import { reportWebVitals } from "./reportWebVitals";

setupAxios();

const rootElement = document.getElementById("root");
const AppElement = <App basename="/" store={store} persistor={persistor} />;

export default ReactDOM.render(AppElement, rootElement);

reportWebVitals(console.log);
