import { MIN_DESKTOP_WIDTH, MIN_TABLE_WIDTH } from "@constants";
import { isDev } from "@utils";
import React from "react";

type EventsContextProps = {
    useHover: () => [
        hovered: boolean,
        handlers: {
            onMouseOver: () => void;
            onMouseOut: () => void;
        },
    ];
    useResponsiveness: () => {
        isMobileView: boolean;
        isTabletView: boolean;
        isDesktopView: boolean;
    };
};

const EventsContext = {
    State: React.createContext<EventsContextProps>(undefined as unknown as EventsContextProps),
};

const EventsProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
    const { children } = props;

    const useHover: EventsContextProps["useHover"] = () => {
        const [hovered, setHovered] = React.useState<boolean>(false);
        const eventHandlers = React.useMemo(() => {
            return {
                onMouseOver: () => setHovered(true),
                onMouseOut: () => setHovered(false),
            };
        }, []);

        return [hovered, eventHandlers];
    };

    const useResponsiveness = () => {
        const [isMobileView, setIsMobileView] = React.useState(
            0 < window.innerWidth && window.innerWidth < MIN_TABLE_WIDTH,
        );
        const [isTabletView, setIsTabletView] = React.useState(
            MIN_TABLE_WIDTH <= window.innerWidth && window.innerWidth < MIN_DESKTOP_WIDTH,
        );
        const [isDesktopView, setIsDesktopView] = React.useState(window.innerWidth >= MIN_DESKTOP_WIDTH);

        React.useEffect(() => {
            const setResponsiveness = () => {
                setIsMobileView(0 < window.innerWidth && window.innerWidth < MIN_TABLE_WIDTH);
                setIsTabletView(MIN_TABLE_WIDTH <= window.innerWidth && window.innerWidth < MIN_DESKTOP_WIDTH);
                setIsDesktopView(window.innerWidth >= MIN_DESKTOP_WIDTH);
            };
            window.addEventListener("resize", setResponsiveness);
            return () => window.removeEventListener("resize", setResponsiveness);
        }, []);

        return {
            isMobileView,
            isTabletView,
            isDesktopView,
        };
    };

    return (
        <EventsContext.State.Provider value={{ useHover, useResponsiveness }}>{children}</EventsContext.State.Provider>
    );
};

const useEvents = () => {
    const context = React.useContext(EventsContext.State);
    if (!context) throw new Error("Missing context initialization");
    return context;
};

if (isDev()) EventsProvider.displayName = "EventsProvider";
export { useEvents, EventsProvider };
