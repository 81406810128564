import CircularProgress from "@mui/material/CircularProgress";
import { isDev } from "@utils";
import React from "react";

const Loading: React.FC = () => {
    return (
        <span
            style={{
                width: "100%",
                height: "calc(100vh - 130px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <CircularProgress />
        </span>
    );
};

if (isDev()) Loading.displayName = "Loading";
export default React.memo(Loading);
