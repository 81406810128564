export namespace Validators {
    export const equal = (x: any, y: any) => x && y && x === y;

    export const postalCode = (x: string) => {
        const regex = /^[0-9]{5}-[0-9]{3}$/;
        return regex.test(x);
    };

    export const document = (x: string) => {
        const regex =
            /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/;
        return regex.test(x);
    };

    export const phone = (x: string) => {
        const regex = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;
        return regex.test(x);
    };

    export const mail = (x: string) => {
        const regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(x);
    };

    export const date = (x: string) => {
        const regex = /\d{2}\/\d{2}\/\d{4}/;
        const isValidFormat = regex.test(x);
        const isLeapValid = (day: number, month: number, year: number) => {
            const isLeapYear = (year & 3) === 0 && (year % 100 !== 0 || year % 400 === 0);
            const isFebruary = month === 2;
            if (!isFebruary) return true;
            if (isLeapYear && isFebruary) return day <= 29;
            return day <= 28;
        };

        const [day, month, year] = x.split("/");
        return isValidFormat && isLeapValid(+day, +month, +year);
    };
}
