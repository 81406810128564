import TextButton from "@components/TextButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useEvents } from "@providers/EventsProvider";
import { logout } from "@redux/actions/auth.actions";
import { ConnectedProps, connector } from "@redux/store";
import { isDev } from "@utils";
import React from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import { UserPlanStatus } from "@constants";

type Props = ConnectedProps;

const Header: React.FC<Props> = (props) => {
    const { isTabletView } = useEvents().useResponsiveness();

    const [userNameHovered, userNameHoveredHandlers] = useEvents().useHover();
    const history = useHistory();
    const styles = useStyles({ view: isTabletView ? "tablet" : "desktop" });

    return (
        <div className={styles.root}>
            <div className={styles.logo} onClick={() => history.push("/")} />
            <div className={styles.menu}>
                <TextButton
                    fontSize="90%"
                    label="Meu plano"
                    className={styles.menuItem}
                    icon={<ConfirmationNumberOutlinedIcon />}
                    onClick={() => {
                        if (props.user?.details?.planStatus === UserPlanStatus.Inactive) history.push("/planos");
                        else history.push(props.user?.details?.plan ? "/minha-conta?page=plan" : "/planos");
                    }}
                />
                {props.user?.details?.plan && (
                    <TextButton
                        fontSize="90%"
                        label="Meus pedidos"
                        icon={<ShoppingCartIcon />}
                        className={styles.menuItem}
                        onClick={() => history.push("/pedidos")}
                    />
                )}
                <TextButton
                    fontSize="90%"
                    label="Meus dados"
                    className={styles.menuItem}
                    icon={<AccountCircleOutlinedIcon />}
                    onClick={() => history.push("/minha-conta")}
                />
                <span
                    {...userNameHoveredHandlers}
                    className={styles.logoutContainer}
                    onClick={() => props.dispatch(logout())}>
                    <p className={styles.logoutUser}>
                        {isTabletView ? "Sair" : props.user?.details?.name ?? props.auth.session?.user.name}
                    </p>
                    <span
                        className={styles.logoutButton}
                        style={{
                            borderRadius: userNameHovered ? "100%" : undefined,
                            backgroundColor: userNameHovered ? "rgb(215, 215, 215)" : undefined,
                        }}>
                        <ExitToAppOutlinedIcon />
                    </span>
                </span>
            </div>
        </div>
    );
};

if (isDev()) Header.displayName = "Header";
export default React.memo(connector(Header));
