import { APP_BASE_URL, API_REQUEST_TIMEOUT } from "@constants";
import { logout } from "@redux/actions/auth.actions";
import { store } from "@redux/store";
import { List, PaginatedList } from "@types";
import _axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { Strings } from "./strings";

const axiosInstance = _axios.create({
    baseURL: APP_BASE_URL,
    timeout: API_REQUEST_TIMEOUT,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
    },
    validateStatus: (status) => status < 400,
});

export const setupAxios = () => {
    const setHeaders = (config: InternalAxiosRequestConfig) => {
        if (config.skipHeaders) return config;
        config.headers = config.headers ?? {};
        config.headers["X-Correlation-ID"] = Strings.uuid();

        const { auth } = store.getState();
        if (auth?.token) config.headers.Authorization = `JWT ${auth.token}`;

        return config;
    };

    axiosInstance.interceptors.request.use(setHeaders, (err: AxiosError) => {
        throw err?.response?.data || err;
    });

    axiosInstance.interceptors.response.use(
        (response) => response,
        (err: AxiosError<any>) => {
            if (err?.response?.data?.code === "UNAUTHORIZED") {
                store.dispatch(logout() as any);
            }
            throw err?.response?.data || err;
        },
    );
};

type Response<T> = T extends List<T> ? List<T> : T extends PaginatedList<T> ? PaginatedList<T> : T;

export const axios = async <T>(config: AxiosRequestConfig): Promise<Response<T>> => {
    return axiosInstance(config)
        .then((response: AxiosResponse<Response<T>>) => {
            if (!response) throw new Error("No response");
            return response.data;
        })
        .catch((thrown) => {
            if (_axios.isCancel(thrown)) console.error(thrown);
            throw thrown;
        });
};

declare module "axios" {
    export interface AxiosRequestConfig {
        skipHeaders?: boolean;
    }
}
