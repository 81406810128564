import { API, useApi } from "@api";
import { ISession, Session } from "@domains/session";
import { Tokens } from "@utils";
import { createAction } from "redux-actions";
import { ActionDispatcher } from ".";
import { getDetails, userReset } from "./user.actions";

export const loginAction = {
    pending: createAction("auth.login.pending"),
    success: createAction("auth.login.success"),
    failed: createAction("auth.login.failed"),
};

export const logoutAction = {
    pending: createAction("auth.logout.pending"),
    done: createAction("auth.logout.done"),
};

export const login = (username: string, password: string): ActionDispatcher => {
    return async (dispatch) => {
        dispatch(loginAction.pending());

        try {
            const authAPI = useApi(API.Auth);
            const { token } = await authAPI.login(username, password);

            const decodedToken = Tokens.decode<ISession>(token);
            if (!decodedToken) return;
            const session = new Session(decodedToken);

            dispatch(getDetails(session.user.id));
            dispatch(loginAction.success({ session, token }));
        } catch (error) {
            dispatch(loginAction.failed());
        }
    };
};

export const logout = (): ActionDispatcher => {
    return async (dispatch, getState) => {
        dispatch(logoutAction.pending());
        const { auth } = getState();

        try {
            dispatch(logoutAction.done());
            dispatch(userReset());

            if (auth.isLogged && !!auth?.session?.id) {
                const authAPI = useApi(API.Auth);
                await authAPI.logout(auth?.session?.id);
            }
        } catch (error) {
            dispatch(logoutAction.done());
        }
    };
};
