export type IUserPhone = {
    countryCode: string;
    areaCode: string;
    number: string;
};

export class UserPhone implements IUserPhone {
    countryCode: string;
    areaCode: string;
    number: string;

    constructor(userPhone: IUserPhone) {
        this.countryCode = userPhone.countryCode;
        this.areaCode = userPhone.areaCode;
        this.number = userPhone.number;
    }
}
