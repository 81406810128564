import { FAN_PARTNER_API_BASE_URL } from "@constants";
import { IInvoice, Invoice } from "@domains/invoice";
import { IOrder } from "@domains/order";
import { IUser } from "@domains/user";
import { axios } from "@utils";

export class UsersAPI {
    public static instance = () => UsersAPI._instance || new UsersAPI();
    private static _instance: UsersAPI;

    private constructor() {
        UsersAPI._instance = this;
    }

    create = async (data: any) => {
        return axios<{ id: string }>({
            data,
            url: "/users",
            method: "POST",
            baseURL: FAN_PARTNER_API_BASE_URL,
        });
    };

    show = async (userId: string) => {
        return axios<IUser>({
            url: `/users/${userId}`,
            method: "GET",
            baseURL: FAN_PARTNER_API_BASE_URL,
        });
    };

    subscribeToPlan = (userId: string, planId: string, billDay?: string) => {
        return axios<Invoice>({
            url: `/users/${userId}/plans/${planId}`,
            method: "POST",
            baseURL: FAN_PARTNER_API_BASE_URL,
            data: {
                billDay,
            },
        });
    };

    listOrders = (userId: string) => {
        return axios<IOrder[]>({
            url: `/users/${userId}/orders`,
            method: "GET",
            baseURL: FAN_PARTNER_API_BASE_URL,
        });
    };

    listOrderInvoices = (userId: string, orderId: string) => {
        return axios<IInvoice[]>({
            url: `/users/${userId}/orders/${orderId}/invoices`,
            method: "GET",
            baseURL: FAN_PARTNER_API_BASE_URL,
        });
    };

    updateUser = (userId: string, data: any) => {
        return axios<IUser>({
            data,
            url: `/users/${userId}`,
            method: "PATCH",
            baseURL: FAN_PARTNER_API_BASE_URL,
        });
    };

    cancelUserPlan = (userId: string) => {
        return axios<IUser>({
            url: `/users/${userId}/plans`,
            method: "DELETE",
            baseURL: FAN_PARTNER_API_BASE_URL,
        });
    };
}
