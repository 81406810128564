import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { NotificationContextConsumer, variants } from "@providers/NotificationsProvider";
import { isDev } from "@utils";
import clsx from "clsx";
import React from "react";
import { useStyles } from "./styles";

const Notify = () => {
    const classes = useStyles();

    return (
        <NotificationContextConsumer>
            {({ isOpen, message, variant, autoHideDuration, hide, onActionClick }) => {
                if (!message) return null;
                const Icon = variants[variant || "info"];
                return (
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        style={{
                            zIndex: 9999,
                        }}
                        open={isOpen}
                        TransitionComponent={(_) => <Slide {..._} direction="up" />}
                        autoHideDuration={autoHideDuration || 5000}
                        onClose={hide}>
                        <SnackbarContent
                            className={clsx(classes[variant || "info"], classes.content)}
                            aria-describedby="client-snackbar"
                            message={
                                <span className={classes.message}>
                                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                                    {message ?? variant}
                                </span>
                            }
                            action={
                                onActionClick ? (
                                    <React.Fragment>
                                        <IconButton
                                            key="confirm"
                                            aria-label="Confirm"
                                            onClick={() => {
                                                onActionClick();
                                                hide();
                                            }}>
                                            <DoneIcon className={classes.icon} />
                                        </IconButton>
                                        <IconButton key="close" aria-label="Close" color="default" onClick={hide}>
                                            <CloseIcon className={classes.icon} />
                                        </IconButton>
                                    </React.Fragment>
                                ) : (
                                    <IconButton key="close" aria-label="Close" color="inherit" onClick={hide}>
                                        <CloseIcon className={classes.icon} />
                                    </IconButton>
                                )
                            }
                        />
                    </Snackbar>
                );
            }}
        </NotificationContextConsumer>
    );
};

if (isDev()) Notify.displayName = "Notify";
export default React.memo(Notify);
