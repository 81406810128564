import { POSTAL_CODE_API_BASE_URL } from "@constants";
import { IUserAddress } from "@domains/user-address";
import { axios } from "@utils";

export class PostalCodeAPI {
    public static instance = () => PostalCodeAPI._instance || new PostalCodeAPI();
    private static _instance: PostalCodeAPI;

    private constructor() {
        PostalCodeAPI._instance = this;
    }

    getAddress = async (postalCode: string) => {
        return axios<{
            cep: string;
            logradouro: string;
            complemento: string;
            bairro: string;
            localidade: string;
            uf: string;
            ibge: string;
            gia: string;
            ddd: string;
            siafi: string;
        }>({
            url: `${postalCode}/json`,
            method: "GET",
            baseURL: POSTAL_CODE_API_BASE_URL,
            skipHeaders: true,
        }).then(
            (data) =>
                ({
                    postalCode,
                    city: data.localidade,
                    country: "Brasil",
                    name: data.logradouro,
                    neighborhood: data.bairro,
                    state: data.uf,
                } as Omit<IUserAddress, "complement" | "number">),
        );
    };
}
