export namespace Numbers {
    export const closest = (reference: number, alternatives = [1, 5, 10, 15, 20, 25]): number => {
        let closestNumber = alternatives[0];
        let closestDiff = reference - alternatives[0];
        for (const alternative of alternatives) {
            const diff = Math.abs(alternative - reference);
            if (diff <= closestDiff && reference <= alternative) {
                closestDiff = diff;
                closestNumber = alternative;
            }
        }

        return closestNumber;
    };
}
