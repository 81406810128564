import { useEvents } from "@providers/EventsProvider";
import { isDev } from "@utils";
import clsx from "clsx";
import React from "react";
import { useStyles } from "./styles";

type Props = {
    label: string;
    style?: React.CSSProperties;
    fontSize?: number | string;
    onClick: () => void;
    icon?: JSX.Element;
    className?: string;
    disabled?: boolean;
};

const TextButton: React.FC<Props> = (props) => {
    const styles = useStyles();
    const { useHover } = useEvents();
    const [hovered, eventHandlers] = useHover();

    return (
        <div
            {...eventHandlers}
            className={clsx(styles.element, props.className)}
            onClick={() => props.disabled || props.onClick()}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                textDecoration: hovered && !props.disabled ? "underline" : "none",
                cursor: hovered && !props.disabled ? "pointer" : "default",
                ...(props.style ?? {}),
            }}>
            {props.icon}
            <p
                style={{
                    fontSize: props.fontSize ?? "1rem",
                    marginLeft: props.icon ? 7.5 : undefined,
                }}>
                {props.label}
            </p>
        </div>
    );
};

if (isDev()) TextButton.displayName = "TextButton";
export default React.memo(TextButton);
