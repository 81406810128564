import { combineReducers } from "redux";
import { reducer as auth } from "./auth.reducer";
import { reducer as plans } from "./plans.reducer";
import { reducer as user } from "./user.reducer";

export const rootReducer = combineReducers({
    auth,
    plans,
    user,
});
