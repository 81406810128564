import { store } from "@redux/store";
import { Plan } from "./plan";
import { IUserAddress, UserAddress } from "./user-address";
import { IUserPhone, UserPhone } from "./user-phone";
import { UserPlanStatus } from "../constants/user-plan-status";

export type IUser = {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    status: string;
    fanId: string;
    fanIdStatus: string;
    username: string;
    allowNotification: boolean;
    name: string;
    document: string;
    birthday: Date;
    phone: IUserPhone;
    address: IUserAddress;
    planId: string | null;
    planStatus: UserPlanStatus;
    planStartDate: Date | null;
    planEndDate: Date | null;
};

export class User implements IUser {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    status: string;
    fanId: string;
    fanIdStatus: string;
    username: string;
    allowNotification: boolean;
    name: string;
    document: string;
    birthday: Date;
    phone: IUserPhone;
    address: IUserAddress;
    planId: string | null;
    planStatus: UserPlanStatus;
    planStartDate: Date | null;
    planEndDate: Date | null;

    constructor(user: IUser) {
        this.id = user.id;
        this.createdAt = user.createdAt;
        this.updatedAt = user.updatedAt;
        this.status = user.status;
        this.allowNotification = user.allowNotification;
        this.fanId = user.fanId;
        this.fanIdStatus = user.fanIdStatus;
        this.username = user.username;
        this.name = user.name;
        this.document = user.document;
        this.birthday = user.birthday;
        this.phone = new UserPhone(user.phone);
        this.address = new UserAddress(user.address);
        this.planId = user.planId;
        this.planStatus = user.planStatus;
        this.planStartDate = user.planStartDate ? new Date(user.planStartDate) : null;
        this.planEndDate = user.planEndDate ? new Date(user.planEndDate) : null;
    }

    get plan(): Plan | null {
        if (!this.planId) return null;
        if (this.planStatus === UserPlanStatus.None) return null;
        const plans = store.getState().plans?.plans ?? [];
        return plans.find((plan) => plan.id === this.planId) ?? null;
    }

    get friendlyPlanStatus(): string {
        const statusFriendly: Record<UserPlanStatus, string> = {
            [UserPlanStatus.None]: "Não contratado",
            [UserPlanStatus.Inactive]: "Inativo",
            [UserPlanStatus.Irregular]: "Irregular",
            [UserPlanStatus.Expired]: "Expirado",
            [UserPlanStatus.PaymentPending]: "Ativo",
            [UserPlanStatus.Regular]: "Ativo",
        };

        return statusFriendly[this.planStatus];
    }
}
