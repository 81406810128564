import Header from "@components/Header";
import MobileMenu from "@components/MobileMenu";
import { isDev } from "@utils";
import React from "react";
import { useEvents } from "./EventsProvider";
import WhatsAppButton from "@components/WhatsAppButton";
import { useSelector } from "react-redux";
import { AppStoreState } from "@redux/store";
import { UserPlanStatus } from "../constants/user-plan-status";

interface LayoutState {
    pathname?: string;
    id?: string;
    splashScreen?: { refs: Record<string, unknown> };
}

const LayoutContext = {
    State: React.createContext<LayoutState | undefined>(undefined),
    Dispatch: React.createContext<React.Dispatch<{}> | undefined>(undefined),
};

const LayoutContextProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
    const { children } = props;
    const { isMobileView, isTabletView } = useEvents().useResponsiveness();
    const {
        user: { details: user },
    } = useSelector((store: AppStoreState) => store);

    const showWhatsAppButton =
        !!user?.planStatus && ![UserPlanStatus.Inactive, UserPlanStatus.None].includes(user?.planStatus);

    if (isMobileView)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100vw",
                    // height: "calc(100vh - 56px)",
                }}>
                <MobileMenu />
                {children}
                {showWhatsAppButton && <WhatsAppButton />}
            </div>
        );
    if (isTabletView)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                <Header />
                <div
                    style={{
                        minHeight: "calc(100vh - 241px)",
                        width: "100%",
                    }}>
                    {children}
                </div>
                {showWhatsAppButton && <WhatsAppButton />}
                <div style={{ background: "black", width: "100%", height: 80, marginTop: 30 }} />
            </div>
        );

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}>
            <Header />
            <div
                style={{
                    minHeight: "calc(100vh - 241px)",
                    width: "100%",
                }}>
                {children}
            </div>
            {showWhatsAppButton && <WhatsAppButton />}
            <div style={{ background: "black", width: "100%", height: 80, marginTop: 30 }} />
        </div>
    );
};

const useLayoutContext = () => {
    const context = React.useContext(LayoutContext.State);
    if (!context) throw new Error("Missing context initialization");
    return context;
};

if (isDev()) LayoutContextProvider.displayName = "LayoutContextProvider";
export { useLayoutContext, LayoutContextProvider };
