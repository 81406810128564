import React from "react";
import { connect } from "react-redux";
import { ActionCreator, Action as ReduxAction, Store, applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers";
import { AuthState } from "./stores/auth.store";
import { PlansState } from "./stores/plans.store";
import { UserState } from "./stores/user.store";

const sagaMiddleware = createSagaMiddleware();

const functionsToCompose = [applyMiddleware(thunk, sagaMiddleware)];
if ("__REDUX_DEVTOOLS_EXTENSION__" in window) functionsToCompose.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());

const persistedReduce = persistReducer({ key: "main-root", storage }, rootReducer);
const initialState = (window as any).__INITIAL_STATE__;
export const store: AppStore = createStore(persistedReduce, initialState, compose(...functionsToCompose));

export type AppStore = Store<AppStoreState, StoreActions>;
export type Action<T, P> = ReduxAction<T> & { payload?: P };
export type Creator<T, P = any> = ActionCreator<Action<T, P>>;

export type StoreActions = Action<string, AuthState>;
export type AppStoreState = {
    auth: AuthState;
    plans: PlansState;
    user: UserState;
};
export type Dispatcher = (args: any) => void;
export type ConnectedProps<T = {}, U = AppStoreState> = T & U & { dispatch: Dispatcher };

export const persistor = persistStore(store);

export const connector = <T>(component: React.FC<any>, stateToProps?: (state: AppStoreState) => T) => {
    const mapStateToProps = (state: AppStoreState) => ({ ...state } as T);
    return connect(stateToProps ?? mapStateToProps)(component);
};
