export type ISessionUser = {
    id: string;
    name: string;
    roles: string[];
    username: string;
};

export class SessionUser implements ISessionUser {
    id: string;
    name: string;
    roles: string[];
    username: string;

    constructor(sessionUser: ISessionUser) {
        this.id = sessionUser.id;
        this.name = sessionUser.name;
        this.roles = sessionUser.roles;
        this.username = sessionUser.username;
    }

    get isAdmin() {
        return this.roles.includes("admin");
    }
}
