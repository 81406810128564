import React from "react";

const LazyPage = (path: string[]) => {
    return React.lazy(() => {
        return import(`../pages/${path.join("/")}`)
            .then((page) => page)
            .catch((error) => ({ default: () => <div>{error}</div> }));
    });
};

export default LazyPage;
