import { FAN_PARTNER_API_BASE_URL } from "@constants";
import { axios } from "@utils";

export class AuthAPI {
    public static instance = () => AuthAPI._instance || new AuthAPI();
    private static _instance: AuthAPI;

    private constructor() {
        AuthAPI._instance = this;
    }

    login = async (username: string, password: string) => {
        return axios<{ token: string }>({
            url: "/login",
            method: "POST",
            baseURL: FAN_PARTNER_API_BASE_URL,
            data: { username, password },
        });
    };

    logout = async (sessionId: string): Promise<void> => {
        await axios({
            method: "DELETE",
            baseURL: FAN_PARTNER_API_BASE_URL,
            url: `/session/${sessionId}`,
        });
    };

    forgetPassword = async (username: string): Promise<void> => {
        await axios({
            url: "/forget-password",
            method: "POST",
            baseURL: FAN_PARTNER_API_BASE_URL,
            data: { username },
            skipHeaders: true,
        });
    };

    resetPassword = async (id: string, password: string): Promise<void> => {
        await axios({
            url: `/reset-password/${id}`,
            method: "POST",
            baseURL: FAN_PARTNER_API_BASE_URL,
            data: { password },
            skipHeaders: true,
        });
    };
}
