import CelebrationIcon from "@mui/icons-material/Celebration";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
import CakeIcon from "@mui/icons-material/Cake";
import RedeemIcon from "@mui/icons-material/Redeem";
import StarRateIcon from "@mui/icons-material/StarRate";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";

export type IPlan = {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    status: string;
    name: string;
    items: string[];
    amount: {
        total: number;
        installments: number;
    };
    startsAt: Date;
    endsAt: Date | null;
};

export class Plan implements IPlan {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    status: string;
    name: string;
    items: string[];
    amount: {
        total: number;
        installments: number;
    };
    startsAt: Date;
    endsAt: Date | null;

    constructor(plan: IPlan) {
        this.id = plan.id;
        this.createdAt = plan.createdAt;
        this.updatedAt = plan.updatedAt;
        this.status = plan.status;
        this.name = plan.name;
        this.items = plan.items;
        this.amount = plan.amount;
        this.startsAt = plan.startsAt;
        this.endsAt = plan.endsAt;
    }

    get color() {
        if (this.name.toLowerCase().includes("black")) return "black";
        if (this.name.toLowerCase().includes("white")) return "white";
        if (this.name.toLowerCase().includes("red")) return "red";
        return "other";
    }

    getBulletIcon(item: string) {
        if (item.toLowerCase().includes("festa") || item.toLowerCase().includes("evento")) return CelebrationIcon;
        if (item.toLowerCase().includes("transporte")) return DirectionsBusFilledIcon;
        if (item.toLowerCase().includes("anivers")) return CakeIcon;
        if (item.toLowerCase().includes("brinde") || item.toLowerCase().includes("presente")) return RedeemIcon;
        if (item.toLowerCase().includes("promo")) return StarRateIcon;
        return this.percent || ConfirmationNumberOutlinedIcon;
    }

    get percent() {
        const foundItem = this.items.find((item) => item.includes("%"));
        if (!foundItem) return;
        return `${foundItem.split("%")[0].split(" ").pop()}%`;
    }

    get price() {
        return this.amount.total / this.amount.installments;
    }
}
