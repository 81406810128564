import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles<any, any>(() => ({
    root: {
        height: 130,
        alignItems: "center",
        display: "flex",
        padding: ({ view }) => (view === "tablet" ? "0 5%" : "0 10%"),
        fontSize: ({ view }) => (view === "tablet" ? "90%" : "100%"),
        justifyContent: "space-between",
        borderBottom: "1px solid rgb(230, 236, 242)",
    },
    logo: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        cursor: "pointer",
        backgroundImage: `url("/assets/images/logo.png")`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        height: 120,
        width: 120,
    },
    menu: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    menuItem: {
        fontWeight: "600",
        paddingLeft: "3rem",
    },
    logoutContainer: {
        marginLeft: "3rem",
        padding: "0 1.5rem",
        borderRadius: "8rem",
        backgroundColor: "rgb(245, 245, 245)",
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    logoutUser: {
        paddingRight: 5,
        fontSize: "90%",
    },
    logoutButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
    },
}));
