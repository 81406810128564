import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Fab from "@mui/material/Fab";
import { useEvents } from "@providers/EventsProvider";
import { isDev } from "@utils";
import clsx from "clsx";
import React from "react";
import { useStyles } from "./styles";

type Props = {};

const WhatsAppButton: React.FC<Props> = () => {
    const [hovered, handlers] = useEvents().useHover();
    const { isDesktopView } = useEvents().useResponsiveness();
    const classes = useStyles();

    if (isDesktopView) {
        return (
            <div
                {...handlers}
                className={clsx(classes.desktop, { [classes.hoveredDesktop]: hovered })}
                onClick={() => window.open("https://chat.whatsapp.com/Jf5C2XDa8XnB485QNX0bY6", "_blank", "noreferrer")}>
                <WhatsAppIcon className={clsx(classes.desktopIcon)} />
                <span className={clsx(classes.text)}>
                    <small>Entre em nosso grupo</small>
                </span>
            </div>
        );
    }

    return (
        <Fab
            style={{
                backgroundColor: "#25d366",
                position: "fixed",
                bottom: 18,
                right: 8,
            }}
            size="medium"
            {...handlers}
            onClick={() => window.open("https://chat.whatsapp.com/Jf5C2XDa8XnB485QNX0bY6", "_blank", "noreferrer")}>
            <WhatsAppIcon className={clsx(classes.desktopIcon)} />
        </Fab>
    );
};

if (isDev()) WhatsAppButton.displayName = "WhatsAppButton";
export default React.memo(WhatsAppButton);
