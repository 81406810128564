import Loading from "@components/Loading";
import Notify from "@components/Notify";
import { EventsProvider } from "@providers/EventsProvider";
import { NotificationProvider } from "@providers/NotificationsProvider";
import { ThemeProvider } from "@providers/ThemeProvider";
import { listPlans } from "@redux/actions/plan.actions";
import { AppStore, ConnectedProps, connector } from "@redux/store";
import { isDev } from "@utils";
import React from "react";
import * as ReactRedux from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Persistor } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./Router";

type Props = {
    store: AppStore;
    basename: string;
    persistor: Persistor;
};
const PersistGateProvider = PersistGate as any;

const AppComponent: React.FC<ConnectedProps<Props>> = (props) => {
    React.useEffect(() => {
        props.dispatch(listPlans());
    }, []);

    return (
        <React.Fragment>
            <ReactRedux.Provider store={props.store}>
                <EventsProvider>
                    <NotificationProvider>
                        <Notify />
                        <PersistGateProvider persistor={props.persistor} loading={null}>
                            <React.Suspense fallback={<Loading />}>
                                <ThemeProvider>
                                    <BrowserRouter basename={props.basename}>
                                        <Router />
                                    </BrowserRouter>
                                </ThemeProvider>
                            </React.Suspense>
                        </PersistGateProvider>
                    </NotificationProvider>
                </EventsProvider>
            </ReactRedux.Provider>
        </React.Fragment>
    );
};

if (isDev()) AppComponent.displayName = "App";
export const App = React.memo(connector(AppComponent));
