import { API, useApi } from "@api";
import { createAction } from "redux-actions";
import { ActionDispatcher } from ".";
import { Plan } from "@domains/plan";

export const listPlansAction = {
    pending: createAction("plans.list.pending"),
    success: createAction("plans.list.success"),
    failed: createAction("plans.list.failed"),
};

export const listPlans = (): ActionDispatcher => {
    return async (dispatch, _getState) => {
        // const currentPlans = getState().plans?.plans ?? [];
        // if (currentPlans.length > 0) return;

        dispatch(listPlansAction.pending());

        try {
            const plansAPI = useApi(API.Plans);
            const { items } = await plansAPI.list();
            const plans = items.map((item) => new Plan(item));

            dispatch(listPlansAction.success({ plans }));
        } catch (error) {
            dispatch(listPlansAction.failed());
        }
    };
};
