import { FAN_PARTNER_API_BASE_URL } from "@constants";
import { IPlan } from "@domains/plan";
import { List } from "@types";
import { axios } from "@utils";

export class PlansAPI {
    public static instance = () => PlansAPI._instance || new PlansAPI();
    private static _instance: PlansAPI;

    private constructor() {
        PlansAPI._instance = this;
    }

    list = async () => {
        return axios<List<IPlan>>({
            url: "/plans",
            method: "GET",
            baseURL: FAN_PARTNER_API_BASE_URL,
        });
    };
}
