import { handleActions } from "redux-actions";
import { loginAction, logoutAction } from "../actions/auth.actions";
import { AuthState, INITIAL_STATE } from "../stores/auth.store";

export const reducer = handleActions<AuthState>(
    {
        [loginAction.success as any]: (state, { payload }) => ({
            ...state,
            isLogged: true,
            token: payload.token,
            session: payload.session,
        }),
        [loginAction.failed as any]: (state) => ({
            ...state,
            isLogged: false,
        }),
        [logoutAction.done as any]: (state) => ({
            ...state,
            isLogged: false,
            token: undefined,
            session: undefined,
        }),
    },
    INITIAL_STATE,
);
