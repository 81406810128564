import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import React, { useState } from "react";

export const variants = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};
type Variant = keyof typeof variants;

export type SnackBarProps = {
    message?: string;
    variant?: Variant;
    autoHideDuration?: number;
    onActionClick?: () => void;
};

type SnackBarState = SnackBarProps & {
    isOpen: boolean;
};

type NotificationContextProps = SnackBarState & {
    show: (data: SnackBarProps) => void;
    hide: () => void;
};

const NotificationContext = {
    State: React.createContext<NotificationContextProps>(undefined as any),
};

export const NotificationContextConsumer = NotificationContext.State.Consumer;

export function useNotifyier() {
    const context = React.useContext(NotificationContext.State);

    if (!context) throw new Error("No Notification context provided");

    return context;
}

export const NotificationProvider = ({ children }: React.PropsWithChildren<unknown>) => {
    const [snackBar, updateSnackBar] = useState<SnackBarState>({
        isOpen: false,
        variant: "info",
        autoHideDuration: 5000,
    });

    const show = ({ message, variant, autoHideDuration = 5000, onActionClick }: SnackBarProps) =>
        updateSnackBar({
            message: message || variant,
            variant: variant || "warning",
            isOpen: true,
            autoHideDuration,
            onActionClick,
        });

    const hide = () =>
        updateSnackBar({
            isOpen: false,
        });

    return (
        <NotificationContext.State.Provider
            value={{
                ...snackBar,
                show,
                hide,
            }}>
            {children}
        </NotificationContext.State.Provider>
    );
};
