export namespace Dates {
    export const raw = (date: Date) => {
        const [year, month, day] = new Date(date).toISOString().split("T")[0].split("-");
        return {
            year,
            month,
            day,
        };
    };

    export const monthName = (date: Date) => {
        const monthNames = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];

        return monthNames[date.getMonth()];
    };

    export const toLong = (date: Date): string => {
        const { day, year } = Dates.raw(date);
        const month = Dates.monthName(date);

        return `${day} de ${month} de ${year}`;
    };

    export const toShort = (date: Date): string => {
        const { day, month, year } = Dates.raw(date);

        return `${day}/${month}/${year}`;
    };

    export const isPast = (date: Date) => {
        return date.getTime() < new Date().getTime();
    };
}
