import { ISessionUser, SessionUser } from "./session-user";

export type ISession = {
    user: ISessionUser;
    session: {
        expiresAt: Date;
        id: string;
    };
};

export class Session implements ISession {
    user: SessionUser;
    session: {
        expiresAt: Date;
        id: string;
    };

    constructor(session: ISession) {
        this.user = new SessionUser(session.user);
        this.session = session.session;
    }

    get id() {
        return this.session.id;
    }
}
