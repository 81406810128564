import { createUserAction, userDetailsAction, userResetAction } from "@redux/actions/user.actions";
import { INITIAL_STATE, UserState } from "@redux/stores/user.store";
import { handleActions } from "redux-actions";

export const reducer = handleActions<UserState>(
    {
        [createUserAction.success as any]: (state, { payload }) => ({
            ...state,
            id: payload.id,
        }),
        [createUserAction.failed as any]: (state) => ({
            ...state,
            id: undefined,
        }),
        [userDetailsAction.success as any]: (state, { payload }) => ({
            ...state,
            details: payload.details,
            id: payload.id,
        }),
        [userDetailsAction.failed as any]: (state) => ({
            ...state,
            details: undefined,
        }),
        [userResetAction.done as any]: (state) => ({
            ...state,
            details: undefined,
            id: undefined,
        }),
    },
    INITIAL_STATE,
);
