import { NODE_ENV } from "@constants";

export * from "./axios";
export * from "./dates";
export * from "./icons";
export * from "./numbers";
export * from "./strings";
export * from "./tokens";
export * from "./validators";

export const isDev = (): boolean => NODE_ENV !== "production";
