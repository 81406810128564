import jwt from "jwt-decode";

export namespace Tokens {
    export const decode = <T>(token: string): T | null => {
        try {
            const payload = jwt<T>(token);
            return payload;
        } catch (error) {
            return null;
        }
    };
}
