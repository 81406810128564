import { API, useApi } from "@api";
import { User } from "@domains/user";
import { createAction } from "redux-actions";
import { ActionDispatcher } from ".";

export const userDetailsAction = {
    pending: createAction("user.get-details.pending"),
    success: createAction("user.get-details.success"),
    failed: createAction("user.get-details.failed"),
};

export const userResetAction = {
    done: createAction("user.reset.failed"),
};

export const createUserAction = {
    pending: createAction("user.create.pending"),
    success: createAction("user.create.success"),
    failed: createAction("user.create.failed"),
};

export const subscribePlanAction = {
    pending: createAction("user.subscribe-plan.pending"),
    success: createAction("user.subscribe-plan.success"),
    failed: createAction("user.subscribe-plan.failed"),
};

export const userReset = (): ActionDispatcher => {
    return async (dispatch) => {
        return dispatch(userResetAction.done());
    };
};

export const getDetails = (userId?: string): ActionDispatcher => {
    return async (dispatch, getState) => {
        userId = userId || getState().user?.id;
        if (!userId) return;

        dispatch(userDetailsAction.pending());

        try {
            const { show } = useApi(API.Users);
            const user = await show(userId);

            dispatch(userDetailsAction.success({ details: new User(user), id: user.id }));
        } catch (error) {
            dispatch(userDetailsAction.failed());
        }
    };
};
