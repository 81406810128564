import { listPlansAction } from "@redux/actions/plan.actions";
import { INITIAL_STATE, PlansState } from "@redux/stores/plans.store";
import { handleActions } from "redux-actions";

export const reducer = handleActions<PlansState>(
    {
        [listPlansAction.success as any]: (state, { payload }) => ({
            ...state,
            plans: payload.plans,
        }),
    },
    INITIAL_STATE,
);
