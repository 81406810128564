import { InvoiceStatus, OrderStatus, PaymentMethods } from "@constants";
import CreditCardIcon from "@mui/icons-material/CreditCardOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PixIcon from "@mui/icons-material/PixOutlined";
import ReceiptIcon from "@mui/icons-material/ReceiptOutlined";

export const PaymentMethodsIcon = (paymentMethod: PaymentMethods): JSX.Element => {
    const paymentMethodsIcons: Record<PaymentMethods, JSX.Element> = {
        [PaymentMethods.Billet]: <ReceiptIcon />,
        [PaymentMethods.Credit]: <CreditCardIcon />,
        [PaymentMethods.Pix]: <PixIcon />,
        [PaymentMethods.Link]: <LinkIcon />,
    };

    return paymentMethodsIcons[paymentMethod];
};

export const StatusIcon = (status: InvoiceStatus | OrderStatus): JSX.Element => {
    const element = (background: string) => {
        return <div style={{ background, width: 12, height: 12, borderRadius: "100%" }} />;
    };
    const statusIcons: Record<InvoiceStatus | OrderStatus, JSX.Element> = {
        [InvoiceStatus.Canceled]: element("grey"),
        [InvoiceStatus.Created]: element("grey"),
        [InvoiceStatus.Delayed]: element("red"),
        [InvoiceStatus.Paid]: element("green"),
        [InvoiceStatus.Pending]: element("yellow"),
        [InvoiceStatus.Failed]: element("red"),
        [OrderStatus.Canceled]: element("grey"),
        [OrderStatus.Closed]: element("green"),
        [OrderStatus.Opened]: element("yellow"),
    };

    return statusIcons[status];
};
