import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    element: {
        color: "#3A3E40",
        fontStyle: "normal",
        fontWeight: "400",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));
