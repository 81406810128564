export type IUserAddress = {
    postalCode: string;
    country: string;
    state: string;
    city: string;
    neighborhood: string;
    name: string;
    number: string;
    complement?: string;
};

export class UserAddress implements IUserAddress {
    postalCode: string;
    country: string;
    state: string;
    city: string;
    neighborhood: string;
    name: string;
    number: string;
    complement?: string;

    constructor(userAddress: IUserAddress) {
        this.postalCode = userAddress.postalCode;
        this.country = userAddress.country;
        this.state = userAddress.state;
        this.city = userAddress.city;
        this.neighborhood = userAddress.neighborhood;
        this.name = userAddress.name;
        this.number = userAddress.number;
        this.complement = userAddress.complement;
    }
}
