import { amber, blue, green, red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    success: {
        backgroundColor: `${green[600]} !important`,
    },
    error: {
        backgroundColor: `${red[600]} !important`,
    },
    info: {
        backgroundColor: `${blue[600]} !important`,
    },
    warning: {
        backgroundColor: `${amber[700]} !important`,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: 10,
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
    content: {
        margin: 10,
    },
}));
