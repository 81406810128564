import MuiThemeProvider from "@mui/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import { isDev } from "@utils";
import React from "react";

const theme = createTheme({
    typography: {
        fontFamily: ["Poppins !important"].join(","),
        button: {
            textTransform: "capitalize",
        },
    },
    palette: {
        primary: {
            main: "#c84b32 !important",
            contrastText: "#fafafa !important",
            dark: "#212121 !important",
        },
        secondary: {
            main: "#ff5c4f !important",

            contrastText: "#ffffff !important",
        },
        error: {
            main: "#fd397a !important",
        },
    },
});

const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
    const { children } = props;

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

if (isDev()) ThemeProvider.displayName = "ThemeContextProvider";
export { theme, ThemeProvider };
