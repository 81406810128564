import { AuthAPI } from "./auth.api";
import { PlansAPI } from "./plans.api";
import { PostalCodeAPI } from "./postal-code.api";
import { UsersAPI } from "./users.api";

export enum API {
    Auth,
    Users,
    Plans,
    PostalCode,
}

type APIInstance<T extends API = API> = T extends API.Auth
    ? AuthAPI
    : T extends API.Users
    ? UsersAPI
    : T extends API.Plans
    ? PlansAPI
    : T extends API.PostalCode
    ? PostalCodeAPI
    : never;

export const useApi = <T extends API>(api: T): APIInstance<T> => {
    const apiInstanceRecord: Record<API, APIInstance> = {
        [API.Auth]: AuthAPI.instance(),
        [API.Users]: UsersAPI.instance(),
        [API.Plans]: PlansAPI.instance(),
        [API.PostalCode]: PostalCodeAPI.instance(),
    };

    const apiInstance = apiInstanceRecord[api];
    if (!apiInstance) throw Error(`Unknown api: ${api}`);
    return apiInstance as APIInstance<T>;
};
