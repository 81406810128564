import { UserPlanStatus } from "@constants";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartOutlined";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { logout } from "@redux/actions/auth.actions";
import { ConnectedProps, connector } from "@redux/store";
import { isDev } from "@utils";
import React from "react";
import { useHistory } from "react-router-dom";

type Props = ConnectedProps;

const MobileMenu: React.FC<Props> = (props) => {
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    const actions = props.user?.details?.plan
        ? [
              {
                  icon: <ConfirmationNumberOutlinedIcon />,
                  name: "Plano",
                  onClick: () => {
                      if (props.user?.details?.planStatus === UserPlanStatus.Inactive) history.push("/planos");
                      else history.push(props.user?.details?.plan ? "/meu-plano" : "/planos");
                  },
              },
              { icon: <ShoppingCartIcon />, name: "Pedidos", onClick: () => history.push("/pedidos") },
              { icon: <AccountCircleOutlinedIcon />, name: "Dados", onClick: () => history.push("/minha-conta") },
              { icon: <ExitToAppOutlinedIcon />, name: "Sair", onClick: () => props.dispatch(logout()) },
          ]
        : [
              {
                  icon: <ConfirmationNumberOutlinedIcon />,
                  name: "Plano",
                  onClick: () => {
                      if (props.user?.details?.planStatus === UserPlanStatus.Inactive) history.push("/planos");
                      else history.push(props.user?.details?.plan ? "/meu-plano" : "/planos");
                  },
              },
              { icon: <AccountCircleOutlinedIcon />, name: "Dados", onClick: () => history.push("/minha-conta") },
              { icon: <ExitToAppOutlinedIcon />, name: "Sair", onClick: () => props.dispatch(logout()) },
          ];

    return (
        <div
            style={{
                background: "white",
                width: "calc(100% - 16px)",
                borderBottom: "1px solid rgb(230, 236, 242)",
                height: 96,
                paddingLeft: "16px",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
            }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundImage: `url("/assets/images/logo.png")`,
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    height: 84,
                    width: 84,
                }}
            />
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel=""
                FabProps={{ sx: { background: "white !important", border: "none", boxShadow: "none" } }}
                sx={{ position: "absolute", right: 16, top: 20 }}
                icon={<MenuOutlinedIcon style={{ color: "black" }} />}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                direction="down"
                open={open}>
                {actions.map((action) => {
                    return (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            tooltipOpen
                            onClick={() => {
                                setOpen(false);
                                action.onClick();
                            }}
                        />
                    );
                })}
            </SpeedDial>
        </div>
    );
};

if (isDev()) MobileMenu.displayName = "MobileMenu";
export default React.memo(connector(MobileMenu));
