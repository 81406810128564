export const MIN_DESKTOP_WIDTH = 1300;
export const MIN_TABLE_WIDTH = 800;
export const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
export const APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || "/";
export const NODE_ENV = process.env.NODE_ENV;
export const API_REQUEST_TIMEOUT = +(process.env.REACT_APP_API_REQUEST_TIMEOUT || 10_000);
export const FAN_PARTNER_API_BASE_URL = process.env.REACT_APP_FAN_PARTNER_API_BASE_URL || "http://localhost:8008";
export const POSTAL_CODE_API_BASE_URL = process.env.REACT_APP_POSTAL_CODE_API_BASE_URL || "https://viacep.com.br/ws/";
export const BILL_DAYS = ["1", "5", "10", "15", "20", "25"];

export * from "./order-status";
export * from "./invoice-status";
export * from "./payment-methods";
export * from "./payment-providers";
export * from "./user-plan-status";
